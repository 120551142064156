import { getServerUrl } from './functions';
import { IEnvironment } from './interfaces';
import { sharedConfig } from './shared';

const gamesServerDomain = 'games-server-pro.bethehouse.com';
const gamesServerPort = 443;
const gamesServerHost = gamesServerDomain + ':' + gamesServerPort;

const externalGamesServer = {
  host: 'external.bethehouse.com',

  port: 443,
  ssl: true,
};

const tradingServer = {
  host: 'trading-server-pro.bethehouse.com',
  port: 443,
  ssl: true,
};

export const productionEnvironment: IEnvironment = {
  ...sharedConfig,

  production: true,

  apiUrl: 'https://' + gamesServerHost,

  gamesSocket: {
    host: gamesServerDomain,
    port: gamesServerPort,
    ssl: true,
  },

  externalGamesSocket: externalGamesServer,
  externalGamesApi: getServerUrl(externalGamesServer),
  currencyApiUrl: 'https://currency-server-pro.bethehouse.com',

  chatServer: {
    host: 'chat-server-pro.bethehouse.com',
    port: 443,
    ssl: true,
  },
  chatServerApiUrl: 'https://chat-server-pro.bethehouse.com',

  tradingServer,
  tradingServerApiUrl: getServerUrl(tradingServer),

  recaptchaSiteKey: '6Lc7I_0cAAAAABkBroiSSqOMi1CkL2Gbs0EfLwgX',
};
